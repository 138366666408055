export function getSalaryRange({ locale, salaryCurrency, salaryMin, salaryMax }) {
  const intl = new Intl.NumberFormat(locale, { style: 'currency', currency: salaryCurrency, maximumFractionDigits: 0 })

  return `${intl.format(salaryMin)} - ${intl.format(salaryMax)}`
}

/**
 * @param {*} salary
 * @param {{ locale: string, currency: string, currencyDisplay: 'code' | 'symbol'}} config
 */
export function formatSalary(salary, { locale, currency, currencyDisplay = 'symbol' }) {
  const intl = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    currencyDisplay,
  })

  return intl.format(salary)
}
